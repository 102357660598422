import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { getMetrics } from '../services/getMetrics';
import './Login.css';
import VideoModal from './VideoModal';
export const API_URL = process.env.REACT_APP_API_URL;

const funFacts = [
    "Fun Fact: Did you know that AI can now write music! OpenAI's MuseNet can compose music in various styles?",
    "Fun Fact: Did you know that The first AI program was written in 1951 by Christopher Strachey, which played checkers?",
    "Fun Fact: Did you know that AI can help predict earthquakes by analyzing seismic data and patterns?",
    "Fun Fact: Did you know that Google's AI, DeepMind, can diagnose eye diseases as accurately as world-leading doctors?",
    "Fun Fact: Did you know that AI technology is used in agriculture to monitor crop health and increase yield efficiency?"
];

const industries = [
    "Technology", "Healthcare", "Finance", "Education", "Manufacturing", 
    "Retail", "Transportation", "Energy", "Construction", "Hospitality",
    "Telecommunications", "Real Estate", "Media", "Agriculture", "Aerospace",
    "Automotive", "Chemicals", "Food and Beverage", "Government", "Other"
];

function Login({ onComplete }) {
    const [ownerDetails, setOwnerDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        industry: '',
        metrics: {}
    });

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(true);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const { width, height } = useWindowSize();
    const [funFact, setFunFact] = useState('');
    const [showVideoModal, setShowVideoModal] = useState(false);

    useEffect(() => {
        const details = localStorage.getItem('userDetails');
        if (details) {
            setOwnerDetails(JSON.parse(details));
            setShowWelcomeModal(true);
        }

        // Fetch the metrics
        const fetchMetrics = async () => {
            const metricsData = await getMetrics();
            setOwnerDetails(prevDetails => ({
                ...prevDetails,
                metrics: metricsData
            }));
        };

        fetchMetrics();
    }, []);

    useEffect(() => {
        if (showLoadingModal) {
            const randomFact = funFacts[Math.floor(Math.random() * funFacts.length)];
            setFunFact(randomFact);
        }
    }, [showLoadingModal]);

    const handleDetailChange = (field) => (event) => {
        setOwnerDetails({
            ...ownerDetails,
            [field]: event.target.value
        });
    };

    const handleOutsideClick = (event, closeModal) => {
        if (event.target.className === 'modal') {
            alert("Please Sign in to get started!");
        }
    };
    

    const handleStartChattingClick = () => {
        setShowWelcomeModal(false);
        setShowVideoModal(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!termsAccepted) {
            alert('You must agree to the terms of service and data collection to proceed.');
            return;
        }

        setShowLoadingModal(true);
        setShowLoginModal(false);

        try {
            localStorage.setItem('userDetails', JSON.stringify(ownerDetails));
            await createNewUser(ownerDetails);
            setShowLoadingModal(false);
            setShowWelcomeModal(true);
            setIsLoggedIn(true);
            setTimeout(() => {
                setIsLoggedIn(false);
            }, 5000); // Stop confetti after 5 seconds
        } catch (error) {
            setShowLoadingModal(false);
            alert('Failed to process your details: ' + error.message);
        }
    };

    async function createNewUser(userDetails) {
        const response = await fetch(`${API_URL}/api/new_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userDetails)
        });

        if (!response.ok) {
            throw new Error('Failed to log user data');
        }

        return await response.json();
    }

    return (
        <div className="login-container">
            {showLoadingModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowLoadingModal(false))}>
                    <div className="loading-modal-content">
                        <div className="spinner"></div>
                        <p>Signing you in...</p>
                        <br/>
                        <p className="fun-fact">{funFact}</p>
                    </div>
                </div>
            )}

            {showWelcomeModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowWelcomeModal(false))}>
                    <div className="modal-content">
                        {isLoggedIn && <Confetti width={width} height={height} />}
                        <h2>Welcome, {ownerDetails.firstName} {ownerDetails.lastName}!</h2>
                        <br />
                        <button type="submit" onClick={handleStartChattingClick}>Start Chatting</button>
                    </div>
                </div>
            )}

            {showLoginModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowLoginModal(true))}>
                    <div className="modal-content">
                        <h2>Sign up to learn more about AI.</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="First Name *"
                                value={ownerDetails.firstName}
                                onChange={handleDetailChange('firstName')}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Last Name *"
                                value={ownerDetails.lastName}
                                onChange={handleDetailChange('lastName')}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email *"
                                value={ownerDetails.email}
                                onChange={handleDetailChange('email')}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Job Title *"
                                value={ownerDetails.title}
                                onChange={handleDetailChange('title')}
                                required
                            />
                            <select
                                value={ownerDetails.industry}
                                onChange={handleDetailChange('industry')}
                                required
                            >
                                <option value="" disabled>Industry *</option>
                                {industries.map(industry => (
                                    <option key={industry} value={industry}>{industry}</option>
                                ))}
                            </select>
                            <br />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={() => setTermsAccepted(!termsAccepted)}
                                /> 
                                I agree to the <a className="underline" href="https://demystifyaishop.org/pages/terms-of-service" target="_blank" rel="noopener noreferrer">terms of service</a> and data collection. *
                            </label>

                            <br /><br />
                            <label>* Required fields</label>
                            <br />
                            <div>
                                <button type="submit" style={{ backgroundColor: termsAccepted ? 'green' : 'grey', color: 'white', cursor: termsAccepted ? 'pointer' : 'not-allowed' }} disabled={!termsAccepted}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showVideoModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowVideoModal(false))}>
                    <div className="modal-content">
                        {/* <VideoModal 
                            isOpen={showVideoModal} 
                            onClose={() => setShowVideoModal(false)} 
                            url="https://www.youtube.com/embed/UJR5pvdF2Ec?autoplay=1&rel=0 " 
                            refreshOnClose={true}
                        /> */}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;